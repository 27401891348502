import { head, compose, reject, split, isEmpty, path, lte, defaultTo, prop } from 'ramda';

function generateSpyneSelectorId(el) {
  //const num = () => Math.floor(Math.random(10000000) * 10000000);
  var num = function num() {
    return Math.random().toString(36).substring(2, 8);
  };

  ;
  var vsid = "" + num();

  if (el.dataset.vsid === undefined) {
    el.dataset.vsid = vsid;
  } else {
    vsid = el.dataset.vsid;
  }

  return "[data-vsid='" + vsid + "']";
}

function isDevMode() {
  return path(['Spyne', 'config', 'debug'], window) === true;
}

function isNodeElement(el) {
  var nodeCheck = compose(lte(0), defaultTo(-1), prop('nodeType'));
  return nodeCheck(el);
}

function getElOrList(cxt, str, verboseBool) {
  if (verboseBool === void 0) {
    verboseBool = false;
  }

  var list = getNodeListArray(cxt, str, verboseBool);
  return list.length === 1 ? head(list) : list;
}

;

function testSelectors(cxt, str, verboseBool) {
  var el = document.querySelector(cxt);
  var elIsDomElement = compose(lte(0), defaultTo(-1), prop('nodeType'));

  if (elIsDomElement(el) === false) {
    console.warn("Spyne Warning: the el object is not a valid single element, " + el);
    return;
  }

  if (str !== undefined) {
    var query = el.querySelector(str);

    if (query === null) {
      if (isDevMode() === true && verboseBool === true) {
        console.warn("Spyne Warning: the selector, " + str + " does not exist in this el, " + cxt);
      }
    }
  }
}

function getNodeListArray(cxt, str, verboseBool) {
  if (verboseBool === void 0) {
    verboseBool = false;
  }

  var selector = str !== undefined ? cxt + " " + str : cxt;

  var returnSelectorIfValid = function returnSelectorIfValid(selector) {
    try {
      document.querySelectorAll(selector);
    } catch (e) {
      return [];
    }

    return document.querySelectorAll(selector);
  };

  if (verboseBool === true) {
    testSelectors(cxt, str, verboseBool);
  }

  return returnSelectorIfValid(selector);
}

function setInlineCss(val, cxt, str) {
  var arr = getNodeListArray(cxt, str);

  var addInlineCss = function addInlineCss(item) {
    item.style.cssText = val;
  };

  arr.forEach(addInlineCss);
  return this;
}
/**
 *
 * @module ViewStreamSelector
 * @type util
 *
 * @param {String|El} cxt The main element
 * @param {String|El} The selector as a String
 * @returns {function(*=)}
 * @constructor
 */


function ViewStreamSelector(cxt, str) {
  var _this = this;

  cxt = typeof cxt === 'string' ? cxt : generateSpyneSelectorId(cxt);
  testSelectors(cxt, str, false);

  function selector(str) {
    return ViewStreamSelector(cxt, str);
  }
  /**
   * Convenience method to map through a NodeList.
   *
   * @param {Function} fn
   * @returns An array of elements
   */


  selector.map = function (fn) {
    return Array.from(getNodeListArray(cxt, str)).map(fn);
  };
  /**
   * Convenience method to iterate through a NodeList
   *
   * @param {Function} fn
   * @returns An array of elements
   */


  selector.forEach = function (fn) {
    return Array.from(getNodeListArray(cxt, str)).map(fn);
  };

  selector.getNodeListArray = function () {
    return getNodeListArray(cxt, str);
  };
  /**
   *
   * Adds the class to the Element or to the NodeList.
   * @param {String} c
   *
   *
   * @property {String} c - = undefined; The class to be added.
   *
   **/


  selector.addClass = function (c) {
    var arr = getNodeListArray(cxt, str);

    var addClass = function addClass(item) {
      return item.classList.add(c);
    };

    arr.forEach(addClass);
    return _this;
  };
  /**
   *
   * @param {String} c
   * @desc Removes the class to the Element or to the NodeList.
   */


  selector.removeClass = function (c) {
    var arr = getNodeListArray(cxt, str);

    var removeClass = function removeClass(item) {
      item.classList.remove(c);
    };

    arr.forEach(removeClass);
    return _this;
  };
  /**
   *
   * @param {String} c
   * @desc Sets the class to equal exactly the class string.
   */


  selector.setClass = function (c) {
    var arr = getNodeListArray(cxt, str);
    /**
    * NON IE CLEANER SOLUTION
    * const removeClass = item => item.classList = c;
    *  arr.forEach(removeClass);
    */

    var setTheClass = function setTheClass(item) {
      var removeClassStrArr = compose(reject(isEmpty), split(' '))(item.className);
      var classStrArr = c.split(" ");

      var remover = function remover(s) {
        return item.classList.remove(s);
      };

      var adder = function adder(str) {
        return item.classList.add(str);
      };

      removeClassStrArr.forEach(remover);
      classStrArr.forEach(adder);
    };

    arr.forEach(setTheClass);
    return _this;
  };

  selector.unmount = function () {//console.log('unmounting selector ', this);
  };
  /**
   *
   * @param {String} c
   * @param {Boolean} bool Default is undefined.
   * @desc Sets the class based on the provided boolean or the toggles the class.
   *
   * @example
   * this.props.el$.toggleClass('myclass', true);
   *
   */


  selector.toggleClass = function (c, bool) {
    var arr = getNodeListArray(cxt, str);

    var toggleClass = function toggleClass(item) {
      bool = bool !== undefined ? bool : !item.classList.contains(c);
      bool ? item.classList.add(c) : item.classList.remove(c);
    };

    arr.forEach(toggleClass);
    return _this;
  };

  selector.toggle = function (c, bool) {
    selector.toggleClass(c, bool);
    return _this;
  };
  /**
   * Attaches html to the Selector's element
   * @param htmlElement
   */


  selector.appendChild = function (htmlElement) {
    if (selector.el.length !== 0) {
      selector.el.appendChild(htmlElement);
    } else {
      console.warn("Spyne Warning: The selector, " + str + " does not appear to be valid!");
    }

    return selector.el;
  };
  /**
   *
   * Adds class with a delay of 1ms to allow css to register a transition.
   * @param c
   */


  selector.addAnimClass = function (c) {
    var delayAddClass = function delayAddClass() {
      var arr = getNodeListArray(cxt, str);

      var addClass = function addClass(item) {
        return item.classList.add(c);
      };

      arr.forEach(addClass);
    };

    window.setTimeout(delayAddClass, 1);
  };
  /**
   *
   * @param {String} c
   * @param {String|HTMLElement} sel The selector for the element.
   * @desc Sets the class active HTMLElement from a NodeList.
   */


  selector.setActiveItem = function (c, sel) {
    var arr = getNodeListArray(cxt, str);
    var currentEl = typeof sel === 'string' ? getElOrList(cxt, sel) : sel;

    var toggleBool = function toggleBool(item) {
      return item.isEqualNode(currentEl) ? item.classList.add(c) : item.classList.remove(c);
    };

    if (isNodeElement(currentEl) === true) {
      arr.forEach(toggleBool);
    } else if (isDevMode() === true) {
      //console.log("SEL IS ",sel,c);
      console.warn("Spyne Warning: The selector, " + sel + ", does not appear to be a valid item in setActiveItem: " + c);
    }

    return _this;
  };
  /**
   *
   * @function el
   *
   * @desc
   * getter for the selector
   *
   * @returns
   * The a single element or a NodeList from the selector
   */

  /**
   *
   * @function length
   *
   * @desc
   * Determines the length of the NodeList
   *
   * @returns
   * The length of the selector as a NodeList
   */

  /**
   *
   * @function exists
   *
   * @desc
   * Determines whether an the selected element exists
   *
   * @returns
   * Boolean
   */


  Object.defineProperty(selector, 'el', {
    get: function get() {
      return getElOrList(cxt, str, true);
    }
  });
  Object.defineProperty(selector, 'len', {
    get: function get() {
      return getNodeListArray(cxt, str, false).length;
    }
  });
  Object.defineProperty(selector, 'exists', {
    get: function get() {
      return getNodeListArray(cxt, str, false).length >= 1;
    }
  });
  Object.defineProperty(selector, 'exist', {
    get: function get() {
      return getNodeListArray(cxt, str, false).length >= 1;
    }
  });
  Object.defineProperty(selector, 'nodeList', {
    get: function get() {
      return getNodeListArray(cxt, str);
    }
  });
  Object.defineProperty(selector, 'inline', {
    set: function set(val) {
      return setInlineCss(val, cxt, str);
    }
  });
  Object.defineProperty(selector, 'inlineCss', {
    set: function set(val) {
      return setInlineCss(val, cxt, str);
    }
  });
  return selector;
}

export { ViewStreamSelector };