var createFaderInlineText = function createFaderInlineText(isInBool, t, e, startZeroBool) {
  if (isInBool === void 0) {
    isInBool = false;
  }

  if (t === void 0) {
    t = 1;
  }

  if (e === void 0) {
    e = 'ease';
  }

  if (startZeroBool === void 0) {
    startZeroBool = false;
  }

  var initOpacityZero = startZeroBool === true ? 'opacity:0;' : '';
  var opacity = isInBool === true ? 1 : 0;
  return initOpacityZero + "transition: opacity " + t + "s " + e + "; opacity: " + opacity + ";";
};

export function fadein(el, t) {
  var currentOpacity = window.getComputedStyle(el).opacity * 1;
  var startAtZero = currentOpacity === 1;
  var inlineCss = createFaderInlineText(true, t, 'ease', startAtZero);
  el.style.cssText += inlineCss;
}
export function fadeout(el, t, callback) {
  var inlineCss = createFaderInlineText(false, t, 'ease');
  el.style.cssText += inlineCss; // window.setTimeout(callback, t * 1000);

  el.addEventListener('transitionend', callback, false);
}