// import Spyne from '../spyne';
import { arrFromMapKeys } from '../utils/frp-tools';
import { compose, path, is } from 'ramda';

var Observable = require('rxjs');

var Subject = require('rxjs'); // console.log('channels config loaded ',R,Rx);


var registeredStreamNames = function registeredStreamNames() {
  return {
    includes: function includes() {
      return window.Spyne !== undefined ? arrFromMapKeys(window.Spyne.channels.map) : ['CHANNEL_ROUTE', 'CHANNEL_UI', 'CHANNEL_WINDOW', 'DISPATCHER'];
    }
  };
}; // getGlobalObj().channelsListArr;


var registeredSteps = ['LOAD', 'RENDER', 'MOUNT', 'UNMOUNT', 'DISPOSE', 'GARBAGE_COLLECT', 'UPDATE'];
var registeredLifeStreamTypes = ['parent', 'self', 'child', 'children', 'view'];
var registeredStreamTypes = ['Observable', 'BehaviorSubject', 'Subject', 'Observer', 'Subscriber', 'FromEventObservable'];
var registeredActions = ['subscribe', 'combineLatest'];

var getRxType = function getRxType(obs) {
  return obs().constructor.name;
}; // let getObservableType = (obs) => obs.constructor.name;


var confirmObservable = function confirmObservable(obs) {
  return obs.subscribe !== undefined;
}; // let pullMainRoute = (str) => str.replace(/^(\/?)(.*)(\/)(.*)/g, '$2');


var baseValidations = [];
var viewInfoValidations = [];
var uiValidations = [];
var lifestreamValidations = [];
var stepValidations = [];
var stepDisposeValidations = [];
var stepUpdateValidations = [];
var routeValidations = [];
var StreamsConfig = [];

if (compose !== undefined && Observable !== undefined) {
  //  ===========================================================================
  // ALL VALIDATIONS ADD THE BASE VALIDATIONS THROUGH CONCATENATION
  //  ===========================================================================
  baseValidations = [{
    error: "need to match a valid name within " + registeredStreamTypes,
    predicate: function predicate(payload) {
      return registeredStreamNames().includes(payload.name);
    }
  }, {
    error: "param 'observable' must contain a valid Observable",
    // predicate: payload => registeredStreamTypes.includes(getObservableType(payload.observable))
    predicate: function predicate(payload) {
      return confirmObservable(payload.observable);
    }
  }, {
    error: 'param action must be a registered action',
    predicate: function predicate(payload) {
      return registeredActions.includes(payload.action);
    }
  }]; //  ===========================================================================
  // THESE VALIDATIONS ARE CONCATENATED WHEN THE OBSERVABLE REFERS TO A VIEW
  //  ===========================================================================

  viewInfoValidations = [{
    error: 'needs vsid number in srcElement',
    predicate: compose(is(String), path(['data', 'srcElement', 'vsid']))
  }, {
    error: 'needs a id in srcElement',
    predicate: compose(is(String), path(['data', 'srcElement', 'id']))
  }]; //  ===========================================================================
  // NO SPECIFIC UI VALIDATIONS AT THIS TIME -- IT JUST ADD OTHERS
  //  ===========================================================================

  uiValidations = function uiValidations() {
    var uiValidations = [];
    return uiValidations.concat(baseValidations).concat(viewInfoValidations);
  }; //  ===========================================================================
  // NO SPECIFIC LIFESTREAM VALIDATIONS AT THIS TIME -- IT JUST ADD OTHERS


  var lifeStreamValidations = [{
    error: "need to match a valid name within " + registeredStreamTypes,
    predicate: function predicate(payload) {
      return registeredStreamNames().includes(payload.name);
    }
  }, {
    error: "needs one of the following step strings: " + registeredSteps,
    predicate: function predicate(payload) {
      return registeredSteps.includes(payload.STEP);
    }
  }, {
    error: "type needs to one of the following: " + registeredLifeStreamTypes,
    predicate: function predicate(payload) {
      return registeredLifeStreamTypes.includes(payload.type);
    }
  }, {
    error: 'viewId needs to be added ',
    predicate: function predicate(payload) {
      return payload.viewId !== undefined;
    }
  }];

  stepValidations = function stepValidations() {
    var stepValidations = [];
    return stepValidations.concat(lifeStreamValidations);
  };

  stepDisposeValidations = function stepDisposeValidations() {
    var stepUpdateValidations = [{
      error: 'DISPOSE STEP requires a disposeItem param in the data object',
      predicate: function predicate(payload) {
        return payload.STEP === 'DISPOSE' && payload.data.disposeItems !== undefined;
      }
    }];
    return stepUpdateValidations.concat(lifeStreamValidations);
  };

  stepUpdateValidations = function stepUpdateValidations() {
    var stepUpdateValidations = [{
      error: 'UPDATE STEP requires a data object ',
      predicate: function predicate(payload) {
        return payload.STEP === 'UPDATE' && payload.data !== undefined;
      }
    }];
    return stepUpdateValidations.concat(lifeStreamValidations);
  }; //  ===========================================================================
  // lifestreamValidations
  //  ===========================================================================


  lifestreamValidations = function lifestreamValidations() {
    return lifeStreamValidations.concat(baseValidations).concat(viewInfoValidations);
  }; //  ===========================================================================
  // HERE IS THE ROUTE VALIDATIONS
  //  ===========================================================================


  routeValidations = function routeValidations() {
    var routeValidations = [
      /*
      *
      {
          error: `needs a valid route string within [${registeredRoutes}]`,
          predicate: payload => registeredRoutes.includes(pullMainRoute(payload.data.navigateTo))
      }
      *
      */
    ];
    return routeValidations.concat(baseValidations).concat(viewInfoValidations);
  }; //  ===========================================================================

  /*
  * THE IDEA OF StreamsConfig IS TO COMPLETELY GENERATE ALL APP STREAMS USING THIS OBJECT
  * THIS HAS NOT BEEN IMPLEMENTED -- MAY BE ADDED IN A FUTURE VERSION
  */
  //  ===========================================================================


  StreamsConfig = function StreamsConfig() {
    var streamValidations = [{
      error: "param 'name' must be of a registered type",
      predicate: function predicate(payload) {
        return registeredStreamNames().includes(payload.name);
      }
    }, {
      error: "param 'observable' must contain a valid Observable",
      predicate: function predicate(payload) {
        return registeredStreamTypes.includes(getRxType(payload.observable));
      }
    }, {
      error: 'param action must be a registered action',
      predicate: function predicate(payload) {
        return registeredActions.includes(payload.action);
      }
    }];
    return {
      streams: [{
        init: {
          name: 'DISPATCHER',
          observable: function observable() {
            return new Subject();
          },
          action: 'subscribe'
        },
        structure: {
          type: String,
          observable: Observable || Subject,
          action: String
        },
        validations: streamValidations
      }, {
        init: {
          name: 'UBU',
          observable: function observable() {
            return new Subject();
          },
          action: 'subscribe'
        },
        structure: {
          type: String,
          observable: Observable || Subject,
          action: String
        },
        validations: streamValidations
      }]
    };
  };
}

export { stepDisposeValidations, stepUpdateValidations, stepValidations, uiValidations, routeValidations, lifestreamValidations, registeredStreamNames, StreamsConfig };