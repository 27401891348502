var _this2 = this;

import { defaultTo, match, forEach, test, includes, without, complement, isEmpty, values, isNil, filter, __, keys, compose, head, map, cond, T, allPass, concat, uniq } from 'ramda';

var isIOS = function isIOS() {
  var userAgent = window.navigator.userAgent.toLowerCase(); // let safari = /safari/.test(userAgent);

  var ios = /iphone|ipod|ipad/.test(userAgent);
  return ios === true;
};

var getConstructorName = function getConstructorName(obj) {
  if (obj.constructor.name !== undefined) {
    return obj.constructor.name;
  }

  var re = /^(function\s)(\w+)(\(.*)$/;
  var str = obj.toString();
  return defaultTo(String(str).substr(0, 12), match(re, str)[2]);
};

var arrFromMapKeys = function arrFromMapKeys(map) {
  var arr = [];

  var addKeysToArr = function addKeysToArr(v, k, i) {
    return arr.push(k);
  };

  forEach(addKeysToArr, map);
  return arr;
};

var findStrFromRegexArr = function findStrFromRegexArr(obj, str) {
  if (obj[str] !== undefined) {
    return str;
  }

  var checkIfMatch = function checkIfMatch(s) {
    return test(new RegExp(s), str);
  };

  var checkStrMatch = includes(str);
  var checkIfRegExMatch = compose(includes(true), map(checkIfMatch));
  var runMatchChecks = cond([[checkStrMatch, function () {
    return str;
  }], [checkIfRegExMatch, function () {
    return str;
  }], [T, function () {
    return undefined;
  }]]);
  return runMatchChecks(obj);
};

var findStrOrRegexMatchStr = function findStrOrRegexMatchStr(obj, str) {
  if (obj[str] !== undefined) {
    return str;
  }

  var createRe = function createRe(s) {
    return new RegExp(s);
  };

  var checkerIfRegexMatchExists = compose(head, filter(compose(test(__, str), createRe)));
  return checkerIfRegexMatchExists(keys(obj));
};

var closest = function closest(array, num) {
  var i = 0;
  var minDiff = 1000;
  var ans;

  for (i in array) {
    var m = Math.abs(num - array[i]);

    if (m < minDiff) {
      minDiff = m;
      ans = array[i];
    }
  }

  return ans;
};

var convertDomStringMapToObj = function convertDomStringMapToObj(domMap) {
  var obj = {};

  for (var d in domMap) {
    obj[d] = domMap[d];
  }

  return obj;
}; // const passPageData = pick(['params', 'routeId', 'data'], __);


var subscribeFn = {
  next: function next(x) {
    return console.log("next      " + x);
  },
  error: function error(x) {
    return console.log("error     " + x);
  },
  complete: function complete(x) {
    return console.log("complete  " + x);
  }
};

var right = function right(x) {
  return {
    map: function map(f) {
      return right(f(x));
    },
    fold: function fold(f, g) {
      return g(x);
    },
    inspect: function inspect() {
      return "right(" + x + ")";
    }
  };
};

var ifNilThenUpdate = function ifNilThenUpdate(val, newVal) {
  var isNilBool = isNil(val);
  return isNilBool ? newVal : val;
};

var left = function left(x) {
  return {
    map: function map(f) {
      return left(x);
    },
    fold: function fold(f, g) {
      return f(x);
    },
    inspect: function inspect() {
      return "left(" + x + ")";
    }
  };
};

var checkIfObjIsNotEmptyOrNil = function checkIfObjIsNotEmptyOrNil(obj) {
  var isNotEmpty = compose(complement(isEmpty), head, values);
  var isNotNil = compose(complement(isNil), head, values);
  var isNotNilAndIsNotEmpty = allPass([isNotEmpty, isNotNil]);
  return isNotNilAndIsNotEmpty(obj);
};

var fromNullable = function fromNullable(x) {
  return x !== null ? right(x) : left(null);
};

var findInObj = function findInObj(obj, val, error) {
  if (error === void 0) {
    error = null;
  }

  var found = obj[val];
  return found ? right(found) : left(error);
};

var pullRouteInfo = function pullRouteInfo() {
  var str = pullHashAndSlashFromPath(window.location.hash);
  var routeId = pullMainRoute(str);
  var params = pullParams(str);
  return {
    routeId: routeId,
    params: params
  };
};

var getAllMethodNames = function getAllMethodNames(_this, omittedMethods) {
  if (_this === void 0) {
    _this = _this2;
  }

  if (omittedMethods === void 0) {
    omittedMethods = [];
  }

  var getPropNamesArr = function getPropNamesArr(obj, omittedMethods) {
    if (obj === void 0) {
      obj = _this2;
    }

    if (omittedMethods === void 0) {
      omittedMethods = [];
    }

    return Object.getOwnPropertyNames(obj);
  }; // Filter Methods


  var baseClassMethodsArr = ['length', 'name', 'prototype', 'constructor'];
  omittedMethods = concat(baseClassMethodsArr, omittedMethods);
  var omitPropsFromArr = compose(without(omittedMethods), uniq); // PULL OUT METHOD NAMES

  var methods = omitPropsFromArr(Object.getOwnPropertyNames(_this.constructor.prototype));
  var staticMethods = omitPropsFromArr(getPropNamesArr(_this.constructor));
  var allMethods = concat(methods, staticMethods);
  return {
    methods: methods,
    staticMethods: staticMethods,
    allMethods: allMethods
  }; // return 'fn';
}; // ROUTE REGEX EXPRESSIONS


var removeSlashes = function removeSlashes(str) {
  return str.replace(/^(\/)(.*)/g, '$2');
};

var routeRE = /^(\/?)(\w*)(\/?)(.*)/g;

var pullHashAndSlashFromPath = function pullHashAndSlashFromPath(str) {
  return str.replace(/^(#\/?)(.*)/, '$2');
};

var pullSlashFromPath = function pullSlashFromPath(str) {
  return str.replace(/^(\/?)(.*)/, '$2');
};

var pullMainRoute = function pullMainRoute(str) {
  return str.replace(routeRE, '$2');
};

var pullParams = function pullParams(str) {
  return str.replace(routeRE, '$4');
};

var pullTranslateX = function pullTranslateX(str) {
  return str.replace(/^(matrix)(.*\d*,)(.*\d*,)(.*\d*,)(.*\d*,)(.*\d*)(,.*)/, '$6');
};

var pullTranslateY = function pullTranslateY(str) {
  return str.replace(/^(matrix)(.*\d*,)(.*\d*,)(.*\d*,)(.*\d*,)(.*\d*,)(.*\d)(.*)/, '$7');
};

var pullTranslateYFromHeader = function pullTranslateYFromHeader(str) {
  return str.replace(/^(transform: matrix)(.*\d*,)(.*\d*,)(.*\d*,)(.*\d*,)(.*\d*)(\).*;)/, '$6');
};

export { getConstructorName, arrFromMapKeys, getAllMethodNames, findStrOrRegexMatchStr, findStrFromRegexArr, checkIfObjIsNotEmptyOrNil, isIOS, pullRouteInfo, pullTranslateYFromHeader, pullSlashFromPath, pullHashAndSlashFromPath, closest, pullTranslateY, pullTranslateX, pullMainRoute, pullParams, right, left, fromNullable, findInObj, ifNilThenUpdate, removeSlashes, subscribeFn, convertDomStringMapToObj };