import Validation from 'data.validation';
import { curry, curryN, length, always, reduce } from 'ramda'; // import { curry, curryN, reduce, length, always } from 'ramda';

var success = Validation.Success;
var failure = Validation.Failure;

var validate = function validate() {};

if (curry !== undefined) {
  validate = curry(function (validations, thing) {
    var initial = success(curryN(length(validations), always(thing)));

    var run = function run(acc, v) {
      return acc.ap(v.predicate(thing) ? success(thing) : failure([v.error]));
    };

    return reduce(run, initial, validations);
  });
}

export { validate };