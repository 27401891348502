export function baseCoreMixins() {
  return {
    createpropsMap: function createpropsMap() {
      var wm = new WeakMap();
      var objKey = {
        vsid: this.props.vsid
      };
      wm.set(objKey, this.props);
      return {
        key: objKey,
        weakMap: wm
      };
    },
    gc: function gc() {
      for (var m in this) {
        delete this[m];
      }

      delete this;
    },
    createId: function createId() {
      //let num = Math.floor(Math.random(10000000) * 10000000);
      var num = function num() {
        return Math.random().toString(36).substring(2, 8);
      };

      ;
      return num(); //return `vsid-${num()}`;
    },
    setTraceFunc: function setTraceFunc(debug) {
      return debug === true ? console.log : function () {};
    }
  };
}