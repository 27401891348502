/*import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";*/
import "spynejs-polyfill/ie";
import { SpyneApp, Channel, ViewStream, ChannelFetch } from 'spyne';
import { MainView } from './js/components/main/main-view';
import { ChannelDataReference } from './js/channels/channel-data-reference';
import { ChannelDataSidebar } from './js/channels/channel-data-sidebar';
import { Regexes } from '../utils/regexes';
import { ChannelDataCombined } from './js/channels/channel-data-combined';
import { ChannelModelRoute } from './js/channels/channel-model-route';
import { ChannelScrollData } from './js/channels/channel-scroll-data'; //import 'whatwg-fetch';

import * as R from 'ramda';
import { ChannelLocalStorage } from "./js/channels/channel-localstorage";
import { ChannelConsole } from './js/channels/channel-console';
import { ChannelConsolePayload } from './js/channels/channel-console-payload';
import { ChannelModal } from './js/channels/channel-modal';
import { ChannelTimer } from './js/channels/channel-timer';

function isIe() {
  return window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
}

var isIE = isIe();
var listenForScroll = isIE === false;

var css = require('./scss/main.scss'); //window.R = R;


var spyneConfig = {
  appName: 'spyne-page-guide',
  animGlobals: {
    fadeInTime: .125
  },
  debug: true,
  isIE: isIE,
  scrollNumDisable: 2,
  channels: {
    WINDOW: {
      events: ['beforeunload'],
      debounceMSTimeForScroll: 300,
      listenForScroll: listenForScroll,
      mediqQueries: {}
    },
    ROUTE: {
      type: 'query',
      isHash: false,
      openConsole: false,
      routeData: {},
      routes: {
        routePath: {
          routeName: 'pageId',
          home: '^$|index.html',
          about: 'about',
          404: '.*',
          guide: {
            routePath: {
              routeName: 'section',
              'overview|reference': {
                routePath: {
                  routeName: 'menuItem'
                }
              }
            }
          }
        }
      }
    }
  }
};

var getLocalStorageObj = function getLocalStorageObj() {
  return JSON.parse(localStorage.getItem('spyne_docs')) || {
    type: undefined
  };
};

var checkForRouteType = function checkForRouteType() {
  var obj = getLocalStorageObj();
  var type = obj.type,
      openConsole = obj.openConsole;

  if (type !== undefined) {
    if (type === 'hash') {
      spyneConfig.channels.ROUTE.type = 'slash';
      spyneConfig.channels.ROUTE.isHash = true;
    } else {
      spyneConfig.channels.ROUTE.type = type;
      spyneConfig.channels.ROUTE.isHash = false;
    }
  }

  if (openConsole !== undefined) {
    spyneConfig.channels.ROUTE.openConsole = openConsole;
  }
};

checkForRouteType();
var spyneApp = new SpyneApp(spyneConfig);
spyneApp.registerChannel(new ChannelDataReference('CHANNEL_REFERENCE_DATA'));

var mapFn = function mapFn(e) {
  var mapSections = function mapSections(sec) {
    var addDataProp = function addDataProp(obj) {
      obj['id'] = Regexes.createTemplateId(obj.contentItemName);
      return obj;
    };

    var updateData = R.map(R.compose(R.map(addDataProp), R.path(['section', 'sectionContent'])));
    updateData(sec);
    return sec;
  }; //console.log("MAP ACF ",mapSections(e.acf.sections));


  mapSections(e.acf.sections);
  return e.acf;
};

var docsData = {
  url4: 'http://spyne-cms.com/wp-json/acf/v3/posts/95',
  url: '/data/content-overview.json',
  mapFn: mapFn
};
spyneApp.registerChannel(new ChannelModal('CHANNEL_MODAL'));
spyneApp.registerChannel(new ChannelDataCombined('CHANNEL_COLLECTED_DATA'));
spyneApp.registerChannel(new ChannelModelRoute('CHANNEL_ROUTE_MODEL'));
spyneApp.registerChannel(new ChannelDataSidebar('CHANNEL_SIDEBAR_DATA'));
spyneApp.registerChannel(new ChannelFetch('CHANNEL_OVERVIEW_DATA', docsData));
spyneApp.registerChannel(new ChannelScrollData('CHANNEL_SCROLL_DATA'));
spyneApp.registerChannel(new ChannelLocalStorage());
spyneApp.registerChannel(new ChannelConsole("CHANNEL_CONSOLE"));
spyneApp.registerChannel(new ChannelConsolePayload("CHANNEL_CONSOLE_PAYLOAD"));
spyneApp.registerChannel(new ChannelTimer('CHANNEL_TIMER'));
/*spyneApp.registerChannel(new ChannelFetch('CHANNEL_TEST_DATA', {
  url: '//jsonplaceholder.typicode.com/posts',
  body: {
    title: 'foo',
    body: 'bar',
    userId: 3,
  },
}, true));*/

var appView = new MainView({
  el: document.getElementById('app')
});

var testAdd = function testAdd() {
  var pageHolder = new ViewStream({
    el: document.getElementById('page-holder')
  });
  console.log('page holder is ', pageHolder);

  var testRemove = function testRemove() {
    console.log('test remove ', pageHolder);
    pageHolder.disposeViewStream();
  }; //window.setTimeout(testRemove, 2000);

}; //const testDispose = ()=>appView.disposeViewStream();
//window.setTimeout(testAdd,3000);
//window.setTimeout(testDispose, 2000);