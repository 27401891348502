import { forEach } from 'ramda';
export function gc() {
  var _this = this;

  var cleanup = function cleanup() {
    var loopM = function loopM(m) {
      return void 0;
    };

    forEach(loopM, _this);
  };

  setTimeout(cleanup, 1);
}